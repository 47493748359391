function AppFooterCopyright() {
	return (
		<div>
			<div className="font-general-regular flex justify-center items-center text-center">
				<div className="text-sm text-alternative-dark dark:text-alternative-light">
					Made with
					<a
						href="https://github.com/realstoman/react-tailwindcss-portfolio"
						target="__blank"
						className="hover:underline hover:text-indigo-600 dark:hover:text-indigo-300 ml-1 duration-500"
					>
						React & Tailwind CSS Portfolio
					</a>
				</div>
			</div>
			<div className="font-general-regular flex justify-center items-center text-center">
				<div className="text-lg text-ternary-dark dark:text-ternary-light">
					Copyright &copy; {new Date().getFullYear()} Javier Calvo García
				</div>
			</div>
		</div>
	);
}

export default AppFooterCopyright;
